<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "HeaderBlock",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    containerClass: {
      type: String,
      default:
        "tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-8 tw-mb-8 lg:tw-mb-12 tw-mx-auto tw-max-w-[358px] md:tw-max-w-[554px] lg:tw-max-w-[860px]",
    },
    titleTextColor: {
      type: String,
      default: "tw-text-gray-800",
    },
    descriptionTextColor: {
      type: String,
      default: "tw-text-gray-600",
    },
  },
});
</script>

<template>
  <div :class="containerClass">
    <h2 :class="titleTextColor" class="display-sm tw-font-semibold tw-text-center tw-mb-4 lg:tw-mb-6">
      {{ title }}
    </h2>
    <p v-if="description" :class="descriptionTextColor" class="tw-text-xl-medium lg:tw-text-xl-medium tw-text-center">
      {{ description }}
    </p>
  </div>
</template>
