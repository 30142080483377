<script lang="ts">
import Vue from "vue";
import { PropOptions } from "vue";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { navigationComputed, eventComputed, themesComputed, formComputed } from "@/state/helpers";
import HeroSection from "./components/HeroSection.vue";
import MisSoldSection from "./components/MisSoldSection.vue";
import HowItWorks from "./components/HowItWorks.vue";
import FAQ from "./components/FAQ.vue";
import GetInTouch from "./components/GetInTouch.vue";
import HowToFinance from "./components/HowToFinance.vue";
import WhatComesNext from "./components/WhatComesNext.vue";

const props: {
  padding: PropOptions<string>;
  condition: PropOptions<any>;
} = inputProps({
  excludeDefaults: true,
  extraProps: ["padding", "condition"],
});

export default Vue.extend({
  name: "SlaterGordonPcpLandingPage",
  components: {
    HeroSection,
    MisSoldSection,
    HowItWorks,
    FAQ,
    GetInTouch,
    HowToFinance,
    WhatComesNext,
  },
  props: {
    ...props,
    backgroundImageUrl: {
      type: String,
      default: "",
    },
    buttonLink: {
      type: String,
      default: "",
    },
    isBuilder: {
      type: Boolean,
    },
  },
  computed: {
    ...navigationComputed,
    ...eventComputed,
    ...themesComputed,
    ...formComputed,
  },
  data() {
    return {
      isLoading: false,
      IMAGES: {
        FEATURE_1: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Icon_Money.png",
        FEATURE_2: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Icon_Doc.png",
        FEATURE_3: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Icon_Expert.png",
        LOGO_1: "https://cdn.claimsgate.co.uk/sguk/Landing%2BPage%2BAssets/Changeable+Truspilot.png",
        LOGO_2: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/SGUK_Logo.png",
        HERO: "https://cdn.claimsgate.co.uk/sguk/Landing%2BPage%2BAssets/Untitled-28+Asset+6.png",
        ARROW: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Arrow.png",
        LINE: "https://cdn.claimsgate.co.uk/sguk/Landing%2BPage%2BAssets/line-2.png",
      },
    };
  },
  methods: {
    navigateToButton() {
      if (this.buttonLink) {
        this.$router.push(this.buttonLink);
      }
    },
    getQueryParams() {
      if (typeof window !== "undefined") {
        const params = new URLSearchParams(window.location.search);
        const queryObject: Record<string, string> = {};

        params.forEach((value, key) => {
          queryObject[key] = value;
        });

        return queryObject;
      }
      return {};
    },

    handleRedirect() {
      console.log("handleRedirect");
      this.$store.dispatch("events/fire", { name: "next" });
    },
    scrollToTop() {
      // Scroll to the top
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
});
</script>

<template>
  <div class="slater-gordon-pcp-landing-page">
    <div v-if="isBuilder" class="p-4 text-center">
      <p>Slater and Gordon PCP Landing Page Block</p>
      <p class="text-muted small">This block will display a landing page for Mis-Sold Car Finance claims</p>
    </div>

    <div v-else class="tw-flex tw-flex-col tw-w-full">
      <!-- THIS EMIT REDIRECT FIRES WHEN THE CHILD TELLS US TO-->
      <HeroSection :images="IMAGES" @emitRedirect="handleRedirect" />
      <MisSoldSection :scrollToTop="scrollToTop" @emitRedirect="handleRedirect" />
      <HowItWorks :scrollToTop="scrollToTop" @emitRedirect="handleRedirect" />
      <HowToFinance :scrollToTop="scrollToTop" @emitRedirect="handleRedirect" />
      <WhatComesNext :scrollToTop="scrollToTop" @emitRedirect="handleRedirect" />
      <FAQ :scrollToTop="scrollToTop" @emitRedirect="handleRedirect" />
      <GetInTouch :scrollToTop="scrollToTop" @emitRedirect="handleRedirect" />
    </div>
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700&display=swap");
</style>
