<script lang="ts">
import Vue from "vue";
import { PropType } from "vue";
import TitleBlock from "../TitleBlock.vue";

export default Vue.extend({
  name: "WhatComesNext",
  components: {
    TitleBlock,
  },
  props: {
    scrollToTop: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  data() {
    return {
      sections: [
        {
          id: 1,
          tag: "Day 1-2",
          name: "Letter to lender",
          description:
            "Slater and Gordon Lawyers will reach out to your finance lender on your behalf. They’ll handle the paperwork and negotiations, working to secure the compensation you deserve.",
        },
        {
          id: 2,
          tag: "Day 14",
          name: "Reply expected",
          description:
            "After your complaint is submitted, your lender should respond or at least acknowledge it. Our team will monitor the process and keep you informed, ensuring you’re updated on your claim’s progress.",
        },
        {
          id: 3,
          tag: "4 December 2025",
          name: "Deadline",
          description:
            "The FCA will conclude their investigation in December and announce if you will receive compensation. Until then, we’ll keep you informed on all of the latest updates that could affect your claim.",
        },
        {
          id: 4,
          tag: "After December 2025",
          name: "Final Response Letter",
          description:
            "The FCA will be mandate your lender to provide a Final Response Letter, which should determine if you receive compensation as part of a redress scheme.",
        },
      ],
      IMAGES: {
        ARROWMOBILE: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Vector+7+(1).png",
        ARROWDESKTOP: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Arrow_What_Comes_Next.png",
      },
    };
  },
});
</script>

<template>
  <div
    class="tw-flex tw-flex-col tw-w-full tw-py-8 tw-px-4 lg:tw-py-16 lg:tw-px-32 tw-font-epilogue"
    style="background-color: #b9e6fe"
  >
    <div class="tw-max-w-[358px] md:tw-max-w-[554px] lg:tw-max-w-[1440px] tw-mx-auto lg:tw-px-32 tw-w-full tw-px-2">
      <TitleBlock
        title="What comes next?"
        description="As the leading law firm behind the historic £193 million Volkswagen Emissions settlement, we understand the importance of keeping you informed throughout your case."
      />

      <div class="tw-flex tw-flex-col lg:tw-flex-row tw-flex-wrap tw-relative tw-w-full tw-justify-between tw-gap-6">
        <img
          :src="IMAGES.ARROWMOBILE"
          loading="lazy"
          alt="Arrow"
          class="tw-z-1 lg:tw-hidden tw-block tw-absolute tw-w-8 tw-h-auto md:tw-left-[256px] tw-left-[160px] tw-top-[-40px] tw-transform"
        />
        <img
          :src="IMAGES.ARROWDESKTOP"
          loading="lazy"
          alt="Arrow"
          class="tw-z-1 tw-hidden lg:tw-visible tw-absolute tw-w-8 tw-h-auto tw-left-[160px] tw-top-[-40px] tw-transform"
        />
        <div
          v-for="section in sections"
          :key="section.id"
          class="lg:tw-mb-6 lg:tw-mt-6 tw-mt-2 tw-mb-2 tw-w-full lg:tw-w-[calc(50%-12px)] tw-z-2"
        >
          <div class="card h-100 mb-0 custom-card">
            <div class="card-body m-3">
              <div class="d-flex flex-column align-items-center mb-3 px-2">
                <div
                  class="tw-flex text-xs tw-justify-center tw-items-center tw-gap-2.5 tw-py-2 tw-px-3 tw-rounded-full tw-mb-4"
                  style="background-color: #e0f2fe"
                >
                  {{ section.tag }}
                </div>
                <div
                  class="d-flex flex-column align-items-center display-xs font-weight-semibold mb-4 w-100 text-center"
                  style="color: #f08130"
                >
                  {{ section.name }}
                </div>
                <div class="w-100">
                  <p class="mb-2 text-xl font-weight-regular">
                    {{ section.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-card {
  background-color: #f4faff;
  border-radius: 48px;
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.25);
}
</style>
