import { render, staticRenderFns } from "./WhatComesNext.vue?vue&type=template&id=8c569caa&scoped=true"
import script from "./WhatComesNext.vue?vue&type=script&lang=ts"
export * from "./WhatComesNext.vue?vue&type=script&lang=ts"
import style0 from "./WhatComesNext.vue?vue&type=style&index=0&id=8c569caa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c569caa",
  null
  
)

export default component.exports