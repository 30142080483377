<script lang="ts">
import Vue from "vue";
import { PropType } from "vue";
import TitleBlock from "../TitleBlock.vue";
import ButtonBlock from "../ButtonBlock.vue";

export default Vue.extend({
  name: "MisSoldSection",
  components: {
    TitleBlock,
    ButtonBlock,
  },
  props: {
    scrollToTop: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  methods: {
    emitRedirect() {
      this.$emit("emitRedirect"); // ! I AM GOING TO TELL MY PARENTS THAT I SHOULD BE REDIRECTED
    },
  },
  data() {
    return {
      sections: [
        {
          id: 1,
          name: "Undisclosed or hidden commission",
          img: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Hidden_Commission.png",
          description:
            "An estimated 95% of vehicle finance agreements from April 2007 to the present day included commission payments. Most consumers were unaware of these commissions, meaning they couldn't give informed consent. As a result, these agreements were likely mis-sold, and affected consumers may be entitled to compensation.",
        },
        {
          id: 2,
          name: "Inflated prices and interest rates",
          img: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Inflated_Prices.png",
          description:
            "Before the FCA banned Discretionary Commission Arrangements (DCAs) in 2021, car dealerships could receive higher commissions by signing people up to loans with higher interest rates. Even where there was no DCA, car dealerships could make a fixed commission. This means many people overpaid on interest, potentially costing them thousands of pounds",
        },
        {
          id: 3,
          name: "Sales people mis-leading you",
          img: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Hidden_Commission.png",
          description:
            "Hire purchase (HP) and personal contract purchase (PCP) deals are popular but not always the best fit. If salespeople didn't clearly explain your options, you might be stuck with the wrong contract.",
        },
        {
          id: 4,
          name: "Failure to conduct affordability checks",
          img: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Red_Flags.png",
          description:
            "Credit brokers and lenders must ensure loans are affordable by checking your financial situation. If they failed to do this, you could be stuck in an unaffordable agreement. You may be eligible for compensation.",
        },
      ],
    };
  },
});
</script>

<template>
  <div
    class="tw-flex tw-flex-col tw-w-full tw-py-8 tw-px-4 md:tw-py-16 md:tw-px-32 tw-font-epilogue"
    style="background-color: #dff2fe"
  >
    <div class="tw-relative tw-w-full">
      <div class="tw-max-w-[358px] md:tw-max-w-[554px] lg:tw-max-w-[1184px] tw-mx-auto tw-w-full">
        <TitleBlock
          title="Has your car finance been mis-sold?"
          description="Most people don't realise they could be eligible for a claim. With Car Finance Checks, you can see if you're affected in minutes, and connect with Slater and Gordon Lawyers, a leading UK law firm to support your case."
        />
        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-flex-wrap tw-w-full tw-justify-between tw-gap-6">
          <div v-for="section in sections" :key="section.id" class="tw-mb-6 tw-w-full lg:tw-w-[calc(50%-12px)]">
            <div class="card h-100 mb-0 custom-card">
              <div class="card-body m-3 tw-flex tw-flex-col tw-gap-auto tw-justify-between">
                <div class="d-flex flex-column align-items-start mb-3 px-2">
                  <div
                    class="d-flex flex-column align-items-center display-xs font-weight-semibold mb-4 w-100 text-center"
                    style="color: #0086c9"
                  >
                    <img
                      :src="section.img"
                      :alt="section.name"
                      class="tw-mb-6 tw-h-[146px] lg:tw-h-[174.05px] tw-w-auto"
                      :style="{ width: 'auto' }"
                      loading="lazy"
                    />
                    {{ section.name }}
                  </div>
                  <div class="w-100">
                    <p class="mb-2 text-xl font-weight-regular">
                      {{ section.description }}
                    </p>
                  </div>
                </div>
                <ButtonBlock text="Find My Agreements" :onClick="emitRedirect" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-card {
  background-color: #f5fbff;
  border-radius: 24px;
  border: 2px solid #000000;
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.25);
}
</style>
