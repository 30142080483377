<script lang="ts">
import Vue from "vue";
import BlockText from "@/components/shared/blocks/text/text.vue";
import { titleProps } from "@/helpers/ClaimsGate/blocks/textProps";
export default Vue.extend({
  name: "BlockTitle",
  components: { BlockText },
  props: {
    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },
    ...titleProps(),
    isBuilder: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
<template>
  <div>
    <BlockText
      :text="text"
      :padding="padding"
      :styles="styles"
      :weight="weight"
      :align="align"
      :textColour="textColour"
      :useTheme="useTheme"
      :isBuilder="isBuilder"
      data-cy="title-block"
    >
      <slot></slot>
    </BlockText>
  </div>
</template>
