<script lang="ts">
import Vue from "vue";
import { PropType } from "vue";
import TitleBlock from "../TitleBlock.vue";
import ButtonBlock from "../ButtonBlock.vue";

export default Vue.extend({
  name: "GetInTouch",
  components: {
    TitleBlock,
    ButtonBlock,
  },
  props: {
    scrollToTop: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  methods: {
    emitRedirect() {
      this.$emit("emitRedirect"); // ! I AM GOING TO TELL MY PARENTS THAT I SHOULD BE REDIRECTED
    },
  },
  data() {
    return {};
  },
});
</script>

<template>
  <div class="tw-flex tw-flex-col tw-w-full tw-p-16 tw-items-center tw-gap-6" style="background-color: #b9e6fe">
    <div class="tw-max-w-[358px] md:tw-max-w-[554px] lg:tw-max-w-[1184px] tw-mx-auto tw-w-full">
      <TitleBlock
        title="Get in Touch"
        description="If you would like to contact Slater & Gordon Lawyers, feel free to use the details below or reach out to us directly here:"
      />
      <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-justify-between lg:tw-gap-6 tw-gap-6">
        <div
          class="tw-flex tw-flex-col tw-w-full lg:tw-w-[48%] tw-p-6 tw-rounded-3xl tw-shadow-lg tw-gap-4"
          style="background-color: #e0f2fe"
        >
          <div class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-center tw-gap-4 lg:tw-gap-6">
            <img
              loading="lazy"
              src="https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Phone_Icon.png"
              alt="Contact Us"
              class="tw-w-16 tw-h-16 lg:tw-w-20 lg:tw-h-20 tw-object-contain"
            />

            <p class="tw-text-lg tw-font-medium" style="color: #2b536f">0330 107 6472</p>
          </div>
          <h2 class="tw-text-lg tw-font-semibold tw-text-gray-800 lg:tw-text-start tw-text-center">
            Got questions? Give us a call – we're here to help!
          </h2>
        </div>

        <div
          class="tw-flex tw-flex-col tw-w-full lg:tw-w-[48%] tw-p-6 tw-rounded-3xl tw-shadow-lg tw-gap-4"
          style="background-color: #f0f9ff"
        >
          <div class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-center tw-gap-4 lg:tw-gap-6">
            <img
              loading="lazy"
              src="https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Letter_Icon.png"
              alt="Contact Us"
              class="tw-w-16 tw-h-16 lg:tw-w-20 lg:tw-h-20 tw-object-contain"
            />

            <p class="tw-text-sm lg:tw-text-lg tw-font-medium" style="color: #2b536f">
              carfinanceclaims@slatergordon.co.uk
            </p>
          </div>
          <h2 class="tw-text-lg tw-font-semibold tw-text-gray-800 lg:tw-text-start tw-text-center">
            Have a question? Reach out to us – we’ll get back to you soon!
          </h2>
        </div>
      </div>

      <div class="tw-flex tw-justify-center tw-mt-12">
        <ButtonBlock text="Find My Agreements" :onClick="emitRedirect" :fullWidth="false" />
      </div>
    </div>
  </div>
</template>
