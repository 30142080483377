<script lang="ts">
import Vue from "vue";
import ButtonBlock from "../ButtonBlock.vue";

export default Vue.extend({
  name: "HeroSection",
  components: {
    ButtonBlock,
  },
  data() {
    return {
      carCount: 0,
    };
  },
  props: {
    images: {
      type: Object,
      required: true,
    },
  },
  methods: {
    emitRedirect() {
      this.$emit("emitRedirect"); // ! I AM GOING TO TELL MY PARENTS THAT I SHOULD BE REDIRECTED
    },
  },
});
</script>

<style scoped></style>

<template>
  <div class="tw-flex tw-flex-col tw-w-full" style="background-color: hsl(205, 44%, 10%)">
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-2 lg:tw-p-16">
      <div class="tw-flex tw-flex-col xl:tw-flex-row tw-items-center md:tw-gap-32 tw-gap-4 tw-mb-12 lg:tw-mb-0">
        <div class="tw-flex tw-flex-col tw-w-[358px] md:tw-w-[552px] tw-items-start tw-justify-center tw-gap-4">
          <div class="tw-flex tw-flex-col tw-mt-12 lg:tw-mt-0 tw-items-start tw-gap-6 tw-w-full">
            <div
              class="tw-font-sans tw-font-semibold tw-text-[36px] tw-leading-[44px] tw-text-[#f9f9f9] tw-tracking-tighter"
            >
              <span class="tw-block lg:tw-hidden" data-cy="each-vehicle-you-financed-since-2007-could-be-worth-text">
                Check If You Could Be Owed
                <span class="tw-text-[hsl(201,98%,82%)] tw-underline tw-underline-offset-4">£1000s</span>
              </span>

              <span
                class="tw-hidden lg:tw-block lg:tw-text-[32px] lg:tw-leading-[50px] lg:tw-tracking-tighter"
                data-cy="each-vehicle-you-financed-since-2007-could-be-worth-text-desktop"
                >Check If You Could Be Owed <span class="tw-text-[#a4dffe] tw-underline">£1000s</span></span
              >
            </div>

            <div class="tw-text-lg-regular tw-text-[#f9f9f9]">How many cars have you financed since 2007?</div>
            <!-- how many cars financed counter -->
            <div class="tw-flex tw-items-center tw-gap-2 tw-mt-0 tw-w-full">
              <button
                @click="carCount = Math.max(0, carCount - 1)"
                class="tw-py-6 tw-px-10 tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-bg-[#ffffff] tw-text-gray-900 tw-transition-all hover:tw-bg-[#a4dffe] tw-w-1/3 tw-border-0"
              >
                <span class="tw-text-3xl tw-font-semibold">-</span>
              </button>

              <div
                class="tw-w-1/3 tw-py-6 tw-px-10 tw-text-center tw-text-3xl tw-font-semibold tw-font-sans tw-bg-[#ffffff] tw-text-gray-900 tw-rounded-lg"
              >
                {{ carCount }}
              </div>

              <button
                @click="carCount = carCount + 1"
                class="tw-py-6 tw-px-10 tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-bg-[#ffffff] tw-text-gray-900 tw-transition-all hover:tw-bg-[#a4dffe] tw-w-1/3 tw-border-0"
              >
                <span class="tw-text-3xl tw-font-semibold">+</span>
              </button>
            </div>
          </div>

          <!-- Button Section -->
          <div class="tw-flex tw-flex-col tw-w-full tw-gap-4" data-cy="find-my-agreements-and-claim-button">
            <div class="tw-w-full">
              <ButtonBlock text="Find My Agreements & Claim" :onClick="emitRedirect" />
            </div>

            <!-- Logos and Footer Text -->
            <div class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-w-full">
              <div class="tw-inline-flex tw-items-center tw-justify-center">
                <img
                  loading="lazy"
                  :src="images.LOGO_1"
                  alt="Logo 1"
                  class="tw-w-auto tw-h-[48.36px] tw-object-cover"
                />
              </div>
              <p class="tw-text-md-regular tw-text-gray-100 tw-text-center tw-w-full">
                We are rated <span class="tw-text-gray-100 tw-font-semibold">'Excellent'</span> on Trustpilot
              </p>
            </div>
          </div>
        </div>

        <img
          :src="images.HERO"
          loading="lazy"
          alt="Side Image"
          class="tw-w-[358px] tw-h-[222px] md:tw-w-[568px] md:tw-h-[352px] lg:tw-w-[568px] lg:tw-h-[351.64px] tw-object-contain tw-mb-12 lg:tw-mb-0"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700&display=swap");

.tw-font-epilogue {
  font-family: "Epilogue", sans-serif !important;
}
</style>
