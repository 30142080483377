<script lang="ts">
import Vue from "vue";
import { PropType } from "vue";
import TitleBlock from "../TitleBlock.vue";
import ButtonBlock from "../ButtonBlock.vue";

export default Vue.extend({
  name: "HowItWorks",
  components: {
    TitleBlock,
    ButtonBlock,
  },
  props: {
    scrollToTop: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  methods: {
    emitRedirect() {
      this.$emit("emitRedirect"); // ! I AM GOING TO TELL MY PARENTS THAT I SHOULD BE REDIRECTED
    },
  },
  data() {
    return {
      sections: [
        {
          id: 1,
          name: "Find Your Agreements",
          img: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Magnifying_Glass_Icon.png",
          description: "Use our free calculator to uncover potential finance claims since 2007 in just two minutes.",
        },
        {
          id: 2,
          name: "Check Your Eligibility",
          img: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Profile_Icon.png",
          description:
            "Our dedicated legal team will carefully prepare and submit your finance claim, managing everything from start to finish.",
        },
        {
          id: 3,
          name: "The Finish Line",
          img: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Money_Icon.png",
          description:
            "If you've had multiple finance agreements, you could make more than one claim - each potentially worth thousands.",
        },
      ],
    };
  },
});
</script>

<template>
  <div class="tw-flex tw-flex-col tw-w-full tw-p-16 tw-items-center" style="background-color: #f0f9ff">
    <div class="tw-max-w-[358px] md:tw-max-w-[554px] lg:tw-max-w-[1184px] tw-mx-auto tw-w-full">
      <TitleBlock
        title="How does it work?"
        description="Companies don’t always play fair—but you don’t have to let them get away with it. We will help you check if you’re owed money and take action."
      />
      <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-justify-between lg:tw-gap-6 tw-gap-6">
        <div v-for="section in sections" :key="section.id" class="tw-mb-4 lg:tw-mb-0 tw-w-full lg:tw-w-1/3">
          <div class="card mb-0 custom-card tw-h-full tw-w-full" style="background-color: #ffffff">
            <div class="tw-p-0">
              <div class="tw-flex tw-flex-col tw-items-start tw-py-6 tw-px-[42px]">
                <div
                  class="d-flex flex-column align-items-center display-xs font-weight-semibold mb-4 w-100 text-center"
                  style="color: #0f1728"
                >
                  <img
                    :src="section.img"
                    loading="lazy"
                    :alt="section.name"
                    class="mb-2 d-block"
                    :style="{ height: 'auto', width: '83px', margin: '0 auto' }"
                  />
                  {{ section.name }}
                </div>
                <div class="w-100">
                  <p class="mb-2 text-xl font-weight-regular">
                    {{ section.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-justify-center tw-mt-12">
        <ButtonBlock text="Find My Agreements" :onClick="emitRedirect" :fullWidth="false" :hoverTextColor="'#FCFCFD'" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-card {
  background-color: #f5fbff;
  border-radius: 24px;
  border: 2px solid #7cd4fd;
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.25);
}
</style>
