var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"sg-button tw-py-6 tw-px-10 tw-flex tw-items-center tw-justify-center tw-rounded-lg",class:['tw-transition-colors', _vm.fullWidth ? 'tw-w-full' : ''],style:({
    backgroundColor: _vm.backgroundColor,
    borderColor: _vm.borderColor,
    border: '2px solid ' + _vm.borderColor,
    '--hover-bg-color': _vm.hoverBackgroundColor,
    '--hover-border-color': _vm.hoverBorderColor,
    '--hover-text-color': _vm.hoverTextColor,
  }),attrs:{"disabled":_vm.isLoading},on:{"click":_vm._onClick}},[(!_vm.isLoading)?[_c('div',{staticClass:"tw-flex tw-items-center tw-gap-2"},[_c('span',{class:[
          'tw-leading-none tw-mt-[2px]',
          _vm.textSize === 'sm'
            ? 'tw-text-sm-semibold'
            : _vm.textSize === 'md'
            ? 'tw-text-md-semibold'
            : _vm.textSize === 'lg'
            ? 'tw-text-lg-semibold'
            : _vm.textSize === 'xl'
            ? 'tw-text-xl-semibold'
            : _vm.textSize === '2xl'
            ? 'tw-text-2xl-semibold'
            : 'tw-text-lg-semibold',
        ],style:({ color: _vm.textColor })},[_vm._v(_vm._s(_vm.text))]),_c('font-awesome-icon',{staticClass:"tw-w-[24px] tw-h-[16px]",style:({ color: _vm.textColor }),attrs:{"icon":"arrow-right"}})],1)]:[_c('b-spinner',{staticClass:"spinner-border-sm m-1",attrs:{"role":"status","data-cy":"button-previous-spinner"}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }