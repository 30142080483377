<script lang="ts">
import Vue from "vue";
import { PropType } from "vue";

export default Vue.extend({
  name: "ButtonBlock",
  data() {
    return {
      isLoading: false,
    };
  },

  props: {
    text: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: "#0086C9",
    },
    borderColor: {
      type: String,
      default: "#0086C9",
    },
    textColor: {
      type: String,
      default: "#ffffff",
    },
    hoverBackgroundColor: {
      type: String,
      default: "#2b536f",
    },
    hoverBorderColor: {
      type: String,
      default: "#2b536f",
    },
    hoverTextColor: {
      type: String,
      default: "#FCFCFD",
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    textSize: {
      type: String,
      default: "lg",
    },
  },
  methods: {
    _onClick() {
      this.isLoading = true;
      this.onClick();
    },
  },
});
</script>

<template>
  <button
    @click="_onClick"
    class="sg-button tw-py-6 tw-px-10 tw-flex tw-items-center tw-justify-center tw-rounded-lg"
    :class="['tw-transition-colors', fullWidth ? 'tw-w-full' : '']"
    :style="{
      backgroundColor: backgroundColor,
      borderColor: borderColor,
      border: '2px solid ' + borderColor,
      '--hover-bg-color': hoverBackgroundColor,
      '--hover-border-color': hoverBorderColor,
      '--hover-text-color': hoverTextColor,
    }"
    :disabled="isLoading"
  >
    <template v-if="!isLoading">
      <div class="tw-flex tw-items-center tw-gap-2">
        <span
          :class="[
            'tw-leading-none tw-mt-[2px]',
            textSize === 'sm'
              ? 'tw-text-sm-semibold'
              : textSize === 'md'
              ? 'tw-text-md-semibold'
              : textSize === 'lg'
              ? 'tw-text-lg-semibold'
              : textSize === 'xl'
              ? 'tw-text-xl-semibold'
              : textSize === '2xl'
              ? 'tw-text-2xl-semibold'
              : 'tw-text-lg-semibold',
          ]"
          :style="{ color: textColor }"
          >{{ text }}</span
        >
        <font-awesome-icon icon="arrow-right" class="tw-w-[24px] tw-h-[16px]" :style="{ color: textColor }" />
      </div>
    </template>
    <template v-else>
      <b-spinner class="spinner-border-sm m-1" role="status" data-cy="button-previous-spinner"></b-spinner>
    </template>
  </button>
</template>

<style scoped>
.sg-button {
  transition: all 0.3s ease;
  animation: pulse 2s infinite;
}

.sg-button:hover {
  background-color: var(--hover-bg-color) !important;
  border-color: var(--hover-border-color) !important;
  animation: none; /* Stop pulsing on hover */
}

.sg-button:hover span {
  color: var(--hover-text-color) !important;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(91, 204, 250, 0.4);
  }

  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(91, 204, 250, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(91, 204, 250, 0);
  }
}
</style>
