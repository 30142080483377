<script lang="ts">
import Vue from "vue";
import { PropType } from "vue";
import ButtonBlock from "../ButtonBlock.vue";
import TitleBlock from "../TitleBlock.vue";
export default Vue.extend({
  name: "FAQ",
  components: {
    ButtonBlock,
    TitleBlock,
  },
  props: {
    scrollToTop: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  data() {
    return {
      expandedQuestionId: null as number | null,
      questions: [
        {
          id: 1,
          question: "Am I eligible to make a car finance claim?",
          answer:
            "If you have taken out a car finance contract, such as a hire purchase (HP) or a personal contract purchase (PCP) agreement, before 28 January 2021, you could be eligible to make a car finance refund claim. <br> <br> Currently, there is no firm indication of how far back the FCA investigation is going to go, as commission models such as DCAs were in use for many years before they were banned. However, it is a likely assumption that only contracts made after April 2007 will be considered, as this is the date the FCA took over the regulation of motor finance.",
        },
        {
          id: 2,
          question: "Are all types of vehicle financing eligible to make a claim?",
          answer:
            "You should be eligible if: <br><br> • Your car finance was a personal contract purchase (PCP) (which is a bit like a loan to help you buy the car); or <br> • A hire purchase agreement (which is where you pay off the value of the car in monthly instalments). <br> <br> You probably won't be eligible if you were just leasing a vehicle.",
        },
        {
          id: 3,
          question: "How do I sign up to make a car finance claim?",
          answer:
            "Signing up for a car finance claim doesn't need to be a headache. At Slater and Gordon, we aim to make the process as stress-free as possible – to register your claim with us, simply click on the button below to get started, and we'll do the rest.",
        },
        {
          id: 4,
          question: "Am I affected by the car finance scandal?",
          answer:
            "If you've taken out car finance between April 2007 and January 2021, you could have been affected by the use of hidden commission. To check your eligibility, enter your details above and we'll let you know if you have a claim.",
        },
        {
          id: 5,
          question: "Can I claim for multiple finance agreements?",
          answer:
            "Yes, you can. This is because each claim is made in relation to a singular car finance contract. If you've had multiple vehicles on car finance between 2007 and 2021 which you think may have been mis-sold, you can make a claim for each one. Enter your details above to start your claims today.",
        },
        {
          id: 6,
          question: "How long will the claim take?",
          answer:
            "The length of time it takes to finalise a claim can vary. However, we'll work hard to ensure you get the compensation you deserve and will keep you updated throughout the process. ",
        },
        {
          id: 7,
          question: "Why choose Slater and Gordon?",
          answer:
            "Slater and Gordon's specialist collective actions team are experts in this field. We recently helped more than 70,000 claimants in England and Wales to secure a settlement payment of £193 million winning more compensation than any other law firm in the UK.  ",
        },
      ],
    };
  },
  methods: {
    toggleDropdown(id: number) {
      this.expandedQuestionId = this.expandedQuestionId === id ? null : id;
    },
    isExpanded(id: number) {
      return this.expandedQuestionId === id;
    },
    emitRedirect() {
      this.$emit("emitRedirect"); // ! I AM GOING TO TELL MY PARENTS THAT I SHOULD BE REDIRECTED
    },
  },
});
</script>

<template>
  <div class="tw-flex tw-flex-col tw-w-full tw-bg-[#2b536f]">
    <div class="tw-max-w-[358px] md:tw-max-w-[554px] lg:tw-max-w-[1184px] tw-mx-auto tw-w-full">
      <div class="tw-flex tw-pt-12">
        <TitleBlock title="Frequently Asked Questions" titleTextColor="tw-text-[#F5F5F5]" />
      </div>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mb-16 tw-pt-0 tw-px-2 lg:tw-px-0 tw-w-full tw-space-y-4"
      >
        <b-col v-for="question in questions" :key="question.id" class="tw-w-full">
          <div
            class="faq-item tw-w-full tw-h-auto tw-rounded-lg tw-px-7 tw-py-4 tw-bg-transparent hover:tw-bg-[#355c76] tw-cursor-pointer tw-overflow-hidden tw-transition-all tw-duration-500"
            @click="toggleDropdown(question.id)"
          >
            <div class="tw-flex tw-justify-between tw-items-center tw-w-full">
              <div class="tw-text-lg tw-font-semibold tw-text-[#f9f9f9] tw-lg:text-md tw-lg:font-semibold">
                {{ question.question }}
              </div>
              <i
                class="fa-solid fa-plus tw-text-[#f9f9f9] tw-transform tw-transition-transform tw-duration-500"
                :class="{ 'tw-rotate-45': isExpanded(question.id) }"
              ></i>
            </div>

            <div
              class="tw-transition-all tw-duration-500 tw-overflow-hidden"
              :class="{
                'tw-max-h-0 tw-opacity-0 tw-mt-0': !isExpanded(question.id),
                'tw-max-h-[500px] tw-opacity-100 tw-mt-2': isExpanded(question.id),
              }"
            >
              <p
                class="tw-text-lg tw-font-semibold tw-text-[#f9f9f9] tw-lg:text-md tw-lg:font-semibold tw-py-8"
                v-html="question.answer"
              ></p>
              <div class="tw-flex tw-justify-center">
                <ButtonBlock text="Find My Agreements" :onClick="emitRedirect" :fullWidth="false" />
              </div>
            </div>
          </div>
        </b-col>
      </div>
    </div>
  </div>
</template>

<style scoped>
.faq-item {
  border: 3px solid #355c76 !important; /* Changed to white for better visibility */
}

.faq-item:hover {
  background-color: #355c76 !important;
}
</style>
