<script lang="ts">
import Vue from "vue";
import { PropType } from "vue";
import TitleBlock from "../TitleBlock.vue";
import ButtonBlock from "../ButtonBlock.vue";

export default Vue.extend({
  name: "HowToFinance",
  components: {
    TitleBlock,
    ButtonBlock,
  },
  props: {
    scrollToTop: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  methods: {
    emitRedirect() {
      this.$emit("emitRedirect"); // ! I AM GOING TO TELL MY PARENTS THAT I SHOULD BE REDIRECTED
    },
  },
  data() {
    return {
      sections: [
        {
          id: 1,
          img: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Keys_Icon.png",
          description:
            "You have ever bought a car using a finance arrangement (such as a hire purchase or personal contract purchase)",
        },
        {
          id: 2,
          img: "https://cdn.claimsgate.co.uk/sguk/Landing+Page+Assets/Doc_Icon.png",
          description: "You were not aware of the commission being made to the dealership at the time of purchase",
        },
      ],
    };
  },
});
</script>

<template>
  <div class="tw-flex tw-flex-col tw-w-full tw-p-4 lg:tw-p-16 tw-items-center" style="background-color: #e0f2fe">
    <div class="tw-max-w-[358px] md:tw-max-w-[554px] lg:tw-max-w-[1184px] tw-mx-auto tw-w-full">
      <TitleBlock
        title="Can I make a car finance claim?"
        description="Many people don’t realize they were mis-sold car finance, especially if hidden commissions or extra costs were
          involved. If any of the following apply to you, you could be eligible for compensation."
      />

      <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-justify-between tw-gap-6">
        <div v-for="section in sections" :key="section.id" class="tw-mb-4 lg:tw-mb-0 tw-w-full lg:tw-flex-1">
          <div class="card mb-0 custom-card tw-w-full" style="height: 329px; background-color: #ffffff">
            <div class="card-body tw-p-0">
              <div class="tw-flex tw-flex-col tw-items-center tw-py-6 tw-px-12">
                <div
                  class="tw-flex tw-flex-col tw-items-center tw-font-semibold tw-mb-4 tw-w-full tw-text-center"
                  style="color: #0f1728"
                >
                  <img
                    :src="section.img"
                    loading="lazy"
                    class="tw-mb-2 tw-block"
                    :style="{ height: 'auto', width: '83px', margin: '0 auto' }"
                  />
                </div>
                <div class="tw-w-full tw-flex tw-justify-center">
                  <p class="tw-mb-2 tw-text-xl tw-text-center tw-overflow-auto" style="width: 330px">
                    {{ section.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tw-flex tw-justify-center tw-my-12">
        <ButtonBlock text="Find My Agreements" :onClick="emitRedirect" :fullWidth="false" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-card {
  background-color: #f5fbff;
  border-radius: 24px;
  border: 2px solid #7cd4fd;
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.25);
}
</style>
