<script>
//@ts-check
// eslint-disable-next-line no-unused-vars
import { Props } from "@/types";
import { DateService } from "@/helpers/ClaimsGate/DateService";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import { themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
/** @type { Props.BlockSingleSelect} */
const inputPropsValue = inputProps({ answerType: "string", extraProps: ["options", "other", "label"] });
export default {
  name: "BlockSingleSelect",
  components: { BlockInputWrapper },
  props: {
    ...inputPropsValue,
  },
  data() {
    return {
      isRTL: false,
      selected: null,
      newAnswer: "",
      newOptions: [],
      dateService: new DateService(),
      otherAnswer: "",
    };
  },
  methods: {
    select(index) {
      //window.console.log("calling select with", index);
      this.$emit("update:state", null);

      if (this.other && index === this.newOptions.length - 1) {
        // other has been selected, show other input box
        this.newAnswer = "Other";
        this.selected = index;
        if (this.otherAnswer && this.otherAnswer.length > 0) {
          this.emitOtherAnswer();
        }
      } else {
        this.newAnswer = this.options[index];
        this.selected = index;
        this.$emit("update:answer", this.newAnswer);
      }
    },
    formatOption(value) {
      return this.dateService.dateTripleCheck(value);
    },
    /**
     * @param {number} index
     */
    isSelected(index) {
      if (index === this.selected) {
        return true;
      } else if (this.other && this.options.length - 1 === index && this.answer === "Other") {
        return true;
      } else {
        return false;
      }
    },
    radioInputFinder(node) {
      if (node.nodeName == "INPUT" && node.type == "radio") {
        node.checked = true;
      }
    },
    makeRadioSelection(ref) {
      // iterate all children of the card body looking for nodeName == "INPUT" and type == "radio" and when found set .checked to true
      // var htmlNodeIterator = this.HTMLNodeIterator(); // recursive iterator helper
      this.HTMLNodeIterator().iterate(this.radioInputFinder, this.$refs[ref][0].children[0]);
    },
    /** @this {{ iterate : (task, child) => void}} */
    HTMLNodeIterator() {
      return {
        iterate: function iterate(task, node) {
          for (var x = 0; x < node.childNodes.length; x++) {
            var childNode = node.childNodes[x];
            task(childNode);
            if (childNode.childNodes.length > 0) this.iterate(task, childNode);
          }
        },
      };
    },
    emitOtherAnswer() {
      this.$emit("update:answer", this.otherAnswer);
    },
    reset() {
      this.selected = null;
      this.newAnswer = "";
      this.otherAnswer = "";
      this.$emit("update:answer", "");
      this.$emit("update:state", null);
    },
    mount() {
      this.newOptions = [];
      if (this.options) {
        this.newOptions = this.options;
      }
      // console.log(">>> SINGLE SELECT options", this.options);
      if (this.other && !this.newOptions.includes("Other")) {
        this.newOptions.push("Other");
      }

      if (this.answer) {
        // console.log(">>> SINGLE SELECT answer", this.answer);
        this.newAnswer = this.answer;
        var indexOfAnswer = this.options.indexOf(this.newAnswer);
        if (indexOfAnswer !== -1) {
          //window.console.log("index of answer is..", indexOfAnswer);
          this.$nextTick(() => {
            this.select(indexOfAnswer);
            this.makeRadioSelection("q" + indexOfAnswer);
          });
        } else if (this.other) {
          this.otherAnswer = this.answer;
          this.newAnswer = "Other";
          this.$nextTick(() => {
            const otherIndex = this.newOptions.length - 1;
            this.select(otherIndex);
            this.makeRadioSelection("q" + otherIndex);
          });
        }
      }
    },
  },
  mounted() {
    this.mount();
  },
  watch: {
    options: {
      handler(newOptions) {
        this.newOptions = newOptions;
      },
    },
    language: {
      handler(newVal, oldVal) {
        // console.log(">>> selectedCountry changed", newVal, oldVal);
        if (newVal !== oldVal) {
          if (newVal !== "ar") {
            this.isRTL = false;
          } else {
            this.isRTL = true;
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...themesComputed,
    dataCyLabel: {
      get() {
        return this.label ? this.label.replace(/\s+/g, "") : "";
      },
    },
  },
};
</script>
<style scoped>
[dir="rtl"] .custom-control-inline {
  margin-left: 1rem !important;
}
.selected {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--primary) !important;
  transition: 0.25s;
}
.deselected {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}
.isInvalid {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--danger) !important;
  transition: 0.25s;
}
.isValid {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--success) !important;
  transition: 0.25s;
}
</style>
<template>
  <BlockInputWrapper :info="info" :padding="padding" :label="label" :invalidFeedback="invalidFeedback" :state="state">
    <b-form-radio-group v-slot="{ ariaDescribedby }" v-model="newAnswer" :ref="'single-select-group'">
      <b-row class="justify-content-center no-gutters">
        <template v-for="(option, index) in newOptions">
          <b-col cols="12" class="mb-0" :key="option.id">
            <b-card
              no-body
              class="border mb-0 p-1"
              :class="{
                selected: isSelected(index),
                deselected: !isSelected(index),
                isValid: state === true && isSelected(index),
                isInvalid: state === false,
              }"
              :state="state"
              :ref="'q' + index"
              :key="index"
              @click="
                select(index);
                makeRadioSelection('q' + index);
              "
              @change="$emit('update:state', null)"
              :data-cy="`single-select-card-${dataCyLabel}-${option}`"
            >
              <div
                :class="`custom-radio d-flex b-custom-control-${theme.size} ${
                  theme.size === 'lg' ? 'p-3 pl-4' : theme.size === 'md' ? 'p-2 pl-3' : 'px-1'
                }`"
              >
                <b-form-radio
                  :class="!isRTL ? 'mr-2' : ''"
                  :aria-describedby="ariaDescribedby"
                  :data-cy="`single-select-radio-${dataCyLabel}-${option}`"
                ></b-form-radio>
                <span
                  style="display: inline-block"
                  class="font-weight-normal m-0"
                  :style="theme.textColourStyles"
                  :data-cy="`single-select-radio-label-${dataCyLabel}-${option}`"
                >
                  {{
                    $te("blocks.singleSelect." + formatOption(option).replace(/\.$/, ""))
                      ? $t("blocks.singleSelect." + formatOption(option).replace(/\.$/, ""))
                      : formatOption(option)
                  }}
                </span>
              </div>
            </b-card>
          </b-col>
        </template>
      </b-row>
      <b-input-group v-if="other && newAnswer === 'Other'" :ref="'other'" class="pt-1">
        <b-form-input
          id="input"
          type="text"
          placeholder="Your answer"
          :size="theme.size"
          :required="required"
          :state="state"
          :disabled="disabled"
          v-model="otherAnswer"
          @update="emitOtherAnswer"
          @change="$emit('update:state', null)"
          aria-describedby="input-live-feedback input-live-help"
          trim
          :class="disabled ? 'disabled' : ''"
          data-cy="single-select-other-input"
        >
        </b-form-input>
      </b-input-group>
    </b-form-radio-group>
  </BlockInputWrapper>
</template>
